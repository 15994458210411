import React from 'react';

import { getPage } from '@contentful';
import { initApolloClient } from '@lib/apollo-client';
import { IPage } from '@componentTypes';
import { HOME_PAGE_URL } from '@constants';
import { Page } from '@components';
import { buildContentulEndpoint, toBoolean } from '@utils';


const preview = toBoolean(process.env.PREVIEW);

const client = initApolloClient({
  uri: buildContentulEndpoint(process.env.CONTENTFUL_SPACE, process.env.CONTENTFUL_ENVIRONMENT),
  authHeader: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
  preview
});

type Props = {
  page: IPage;
}

const HomePage = ({ page }: Props) => {
  return <Page page={page} />;
};

export async function getStaticProps(context: any) {
  const env = context.previewData?.env;
  const page = await getPage(client, HOME_PAGE_URL, preview, env);
  return {
    props: {
      page: page,
    }
  };
}

export default HomePage;